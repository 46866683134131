.imageContainer {
  position: relative;
}

.rsvpBox {
  position: absolute;
  top: 10%;
  right: 10px;
  width: 50%;
  text-align: right;
}

.rsvpBoxText {
  color:#FADE8A; 
  text-shadow: 2px 2px 8px black;
}